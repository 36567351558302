.alert-wrapper {
    background: #e5e5e5;
  }

  .my-custom-class .alert-wrapper {
    background: #fefffd;
  }

  .my-custom-class {
    --background: #425855;
  }















  